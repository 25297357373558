import React from "react";
import CardList from "app/components/CardList";
import ClassCard from "app/components/ClassCard";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { Waypoint } from "app/components/Waypoint";
import { Redirect } from "react-router";
import { Routes } from "constants/routes";
import Div from "app/components/Div";
import ScrollToTopOnMount from "app/components/ScrollToTopOnMount";
import { ProgressFilter, SortOrder } from "services/graphql";
import { BodyContainer, CenterWrapper, Container, Title } from "./styles";
import { useHistoryData, useGetMenuOption } from "./hooks";
import { ProgramsCarousel } from "./ProgramsCarousel";
import { Menu } from "./Menu";
import { MenuOptions } from "./types";

interface Props {
  selectedFrom?: string;
}

function History() {
  const { isValidMenuOption } = useGetMenuOption();

  if (!isValidMenuOption) {
    return <Redirect to="/not-found" />;
  }

  return <HistoryInner />;
}

const MenuMapToFilter = {
  [MenuOptions.All]: ProgressFilter.All,
  [MenuOptions.Complete]: ProgressFilter.Completed,
  [MenuOptions.Incomplete]: ProgressFilter.Incomplete,
};

const HistoryInner = ({ selectedFrom = "History" }: Props) => {
  const { selectedOption } = useGetMenuOption();
  const {
    classesHistory,
    programsHistory,
    classesHistoryLoading,
    programsHistoryLoading,
    pageInfo,
    fetchMoreHistory,
  } = useHistoryData({
    orderDirection: SortOrder.Desc,
    filterBy: MenuMapToFilter[selectedOption],
  });

  return (
    <>
      <CenterWrapper mb="16px">
        <Helmet title="History | Online Dance Classes and Tutorials" />
        <ScrollToTopOnMount />
        <Container pt={[4, 4, 4, "42px"]}>
          <Title pb={[4]}>History</Title>
          <Div mb={3}>
            <Menu />
          </Div>
        </Container>
      </CenterWrapper>
      {!programsHistoryLoading && (
        <ProgramsCarousel programs={programsHistory} />
      )}
      <BodyContainer>
        {classesHistoryLoading && <LoaderCentered />}
        {!classesHistoryLoading && classesHistory.length === 0 && (
          <span>
            You haven&apos;t{" "}
            {[MenuOptions.Incomplete, MenuOptions.All].includes(selectedOption)
              ? "started"
              : "completed"}{" "}
            a class. Select a <Link to={Routes.library__classes}>class</Link> to
            get started.
          </span>
        )}
        {!classesHistoryLoading && classesHistory.length > 0 && (
          <>
            {/* Can use when we have more ways to change sort */}
            {/* <SortMenuOptions setOrderDirection={setOrderDirection} /> */}
            <CardList
              mt={2}
              cards={classesHistory}
              renderCard={({ cardData }: any) => {
                return (
                  <ClassCard
                    key={cardData.id}
                    data={cardData}
                    selectedFrom={selectedFrom}
                  />
                );
              }}
            />
          </>
        )}
        {!classesHistoryLoading && (
          <Waypoint
            fetchData={fetchMoreHistory}
            hasMore={pageInfo?.hasNextPage}
          />
        )}
      </BodyContainer>
    </>
  );
};

export default History;
